import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Layout from "../components/layout"
import MainContainer from "../components/main-container"
import styled from "styled-components"
import GhostbutterTagLine from "../components/tagline"
import { Helmet } from "react-helmet"

const TeamHeader = styled.div`
  display: block;
  margin: 1.5em;
  margin-left: 0;
  font-family: ${props => props.theme.fonts.defaultFontBold};
  font-weight: 800;
  font-size: ${props => props.theme.fontSizes.h1};

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const PresskitLink = styled.a`
  display: block;
  margin: 1.5em;
  margin-left: 0;
  font-family: ${props => props.theme.fonts.defaultFontItalic};
  font-style: italic;
  font-size: ${props => props.theme.fontSizes.h3};

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const TeamCapsuleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
`

const TeamCapsule = styled.div`
  /* background-color: ${props => props.bgColor}; */
  border-radius: ${props => props.radius1}% ${props => props.radius2}%
    ${props => props.radius3}% ${props => props.radius4}%;
  position: relative;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center;
  padding: 0.5em;

  &::before {
    content: "";
    padding-bottom: 100%;
    display: block;
  }
`

const TeamCapsuleTitle = styled.div`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  font-family: ${props => props.theme.fonts.defaultFontBold};
  font-weight: 800;
  font-size: ${props => props.theme.fontSizes.h2};

  text-shadow: 0px 0px 20px rgb(0 0 0), 0px 0px 20px rgb(0 0 0);
`

const TeamCapsuleDescription = styled.div`
  white-space: pre-line;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  font-family: ${props => props.theme.fonts.defaultFontItalic};
  font-style: italic;
  font-size: ${props => props.theme.fontSizes.h3};
  margin-bottom: 5px;

  text-shadow: 0px 0px 20px rgb(0 0 0), 0px 0px 20px rgb(0 0 0);
`

const TeamCapsuleDescriptionLink = styled.a`
  white-space: pre-line;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  font-family: ${props => props.theme.fonts.defaultFontItalic};
  font-style: italic;
  font-size: ${props => props.theme.fontSizes.p};

  display: inline-block;

  text-shadow: 0px 0px 20px rgb(0 0 0), 0px 0px 20px rgb(0 0 0);
`

export default function About() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ghostbutter - About</title>
        <link rel="canonical" href="https://ghostbutter.com/about/" />
      </Helmet>
      <Header></Header>
      <MainContainer>
        <GhostbutterTagLine />

        <TeamHeader>Meet the team:</TeamHeader>
        <TeamCapsuleContainer>
          <TeamCapsule
            src="/plopp-gif.gif"
            radius1="56"
            radius2="24"
            radius3="67"
            radius4="53"
          >
            <TeamCapsuleTitle>Konstantin Kopka</TeamCapsuleTitle>
            <TeamCapsuleDescription>
              Design, Development, Music
            </TeamCapsuleDescription>
            <TeamCapsuleDescriptionLink
              href="https://twitter.com/ghostbutter"
              target="_blank"
            >
              Twitter
            </TeamCapsuleDescriptionLink>{" "}
            <TeamCapsuleDescriptionLink
              href="https://instagram.com/ghostbutter"
              target="_blank"
            >
              Instagram
            </TeamCapsuleDescriptionLink>
          </TeamCapsule>
          <TeamCapsule
            src="/spiderkid-gif.gif"
            radius1="45"
            radius2="58"
            radius3="43"
            radius4="38"
          >
            <TeamCapsuleTitle>Philip Feller</TeamCapsuleTitle>
            <TeamCapsuleDescription>Design, Animation</TeamCapsuleDescription>
            <TeamCapsuleDescriptionLink
              href="https://twitter.com/pixelfeller"
              target="_blank"
            >
              Twitter
            </TeamCapsuleDescriptionLink>
          </TeamCapsule>
          <TeamCapsule
            src="/insect-gif.gif"
            radius1="68"
            radius2="57"
            radius3="67"
            radius4="45"
          >
            <TeamCapsuleTitle>Elenor Kopka</TeamCapsuleTitle>
            <TeamCapsuleDescription>Art Direction</TeamCapsuleDescription>
            <TeamCapsuleDescriptionLink
              href="https://twitter.com/elenorkopka"
              target="_blank"
            >
              Twitter
            </TeamCapsuleDescriptionLink>{" "}
            <TeamCapsuleDescriptionLink
              href="https://instagram.com/elenorkopka"
              target="_blank"
            >
              Instagram
            </TeamCapsuleDescriptionLink>
          </TeamCapsule>
        </TeamCapsuleContainer>

        <PresskitLink
          href="http://konstantinkopka.com/press-ghostbutter/index.php"
          target="_blank"
        >
          Fancy yourself a writer? Our presskit's got ya covered!
        </PresskitLink>
      </MainContainer>
      <Footer></Footer>
    </Layout>
  )
}
